import React from "react"

import { Page } from "../../components/elements"
import { PageCenter } from "../../components/elements/wrappers/PageCenter.jsx"

export default function Component() {
    return (
        <Page crumbs={false}>
            <PageCenter text="dark">
                <div
                    style={{
                        minHeight: "554px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <h2 style={{ color: "grey" }} className="display-4">
                        404 Error
                    </h2>
                    <h2 className="lead mt-5">
                        We apologize, but it appears the link you followed was
                        incorrect. Because of the changes to our website, if you
                        are following a link from a PDF document please attempt
                        to find the item from the menu above, or search for the
                        page you were looking for.
                    </h2>
                    <h2 className="lead">
                        If you followed a link and believe this page should
                        exist please contact the{" "}
                        <a
                            href="mailto:egihelp@egi.utah.edu"
                            style={{ fontWeight: 400 }}
                        >
                            webmaster
                        </a>
                        .
                    </h2>
                </div>
            </PageCenter>
        </Page>
    )
}
